import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaWhatsapp, FaTwitter, FaUser, FaPhone, FaHome, FaMapMarkerAlt, FaCarSide, FaPhoneAlt } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useSpring, animated } from 'react-spring';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { QRCodeCanvas } from 'qrcode.react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { motion } from 'framer-motion';

Modal.setAppElement('#root');

function HomePage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [language, setLanguage] = useState("ar");
  const [phoneValid, setPhoneValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  const [formData, setFormData] = useState({
    customer_name: '',
    customer_phone: '',
    home_location: '',
    work_location: '',
    delivery_count: '',
    driver_nationality: '',
    additional_notes: ''
  });

  const fade = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 1000 } });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((isModalOpen || isLanguageModalOpen) && !event.target.closest('.modal-content') && !event.target.closest('.contact-button')) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen, isLanguageModalOpen]);

  const validatePhoneNumber = (phone) => {
    const regex = /^05\d{8}$/;
    return regex.test(phone);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let isFormFilled = true;

    setFormData({
      ...formData,
      [name]: value
    });

    if (name === "customer_name" && value.length < 3) {
      isFormFilled = false;
    }

    if (name === "customer_phone") {
      const isValid = validatePhoneNumber(value);
      setPhoneValid(isValid);
      if (!isValid) {
        isFormFilled = false;
      }
    }

    for (let key in formData) {
      if (!formData[key].trim()) {
        isFormFilled = false;
        break;
      }
    }

    setFormFilled(isFormFilled);
  };

  const sendRequest = async (e) => {
    e.preventDefault();

    const { customer_name, customer_phone, home_location, work_location, delivery_count, driver_nationality, additional_notes } = formData;

    if (!customer_name || customer_name.length < 3) {
      toast.error("الاسم يجب أن يحتوي على 3 أحرف على الأقل.");
      return;
    }

    if (!customer_phone || !validatePhoneNumber(customer_phone)) {
      toast.error("رقم الهاتف غير صحيح. يجب أن يبدأ بـ 05 ويكون مكونًا من 10 أرقام.");
      return;
    }

    const today = new Date();
    const orderId = `ORD-${today.getDate().toString().padStart(2, '0')}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}-${Math.floor(Math.random() * 10000)}`;

    try {
      const response = await fetch('https://mshwark.net/save_data.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          customer_name,
          customer_phone,
          home_location,
          work_location,
          delivery_count,
          driver_nationality,
          additional_notes,
          order_id: orderId
        })
      });

      if (response.ok) {
        toast.success("تم إرسال البيانات وحفظها بنجاح!");

        const whatsappUrl = `https://wa.me/966557124553?text=${encodeURIComponent(`طلب حجز جديد: ${orderId}\nرقم الهاتف: ${customer_phone}\nمكان المنزل: ${home_location}\nمكان الدوام: ${work_location}\nعدد الأفراد: ${delivery_count}\nجنسية السائق المطلوبة: ${driver_nationality}\nرابط محادثة واتساب مع العميل: https://wa.me/966${customer_phone}\nملاحظات مقدم الطلب: ${additional_notes}`)}`;
        window.open(whatsappUrl, '_blank');

        setFormData({
          customer_name: '',
          customer_phone: '',
          home_location: '',
          work_location: '',
          delivery_count: '',
          driver_nationality: '',
          additional_notes: ''
        });
      } else {
        throw new Error("حدث خطأ أثناء إرسال البيانات.");
      }
    } catch (error) {
      console.error('حدث خطأ أثناء الإرسال:', error);
      toast.error("حدث خطأ أثناء الإرسال.");
    }
  };

  const switchLanguage = (lang) => {
    setLanguage(lang);
    const content = {
      ar: 'العربية',
      en: 'الإنجليزية',
      hi: 'الهندية',
      bn: 'البنغالية'
    };
    toast.info(`تم تغيير اللغة إلى ${content[lang]}`);
  };

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openLanguageModal = () => {
    setIsLanguageModalOpen(!isLanguageModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLanguageModalOpen(false);
  };

  const copyPhoneNumber = () => {
    navigator.clipboard.writeText('0552716666');
    toast.success("تم نسخ الرقم، يمكنك استخدامه في أي مكان.");
    closeModal();
  };

  const content = {
    ar: {
      header: "إحجز مشوارك الآن",
      description: "سهولة الحجز والراحة",
      descriptionSub: "مع مشوارك نت",
      adText: [
        "إحجز مشوارك - راحتك بأقل التكاليف في الرياض!",
        "مع 'إحجز مشوارك'، نوفر لك خدمة توصيل سريعة داخل مدينة الرياض. يمكنك اختيار السائق بنفسك، وضمان الوصول في الوقت المحدد بأفضل الأسعار.",
        "خدماتنا تشمل توصيل الموظفين والطلاب إلى مواقع عملهم ودراستهم. اختر من بين مجموعة متنوعة من السائقين المحترفين وابدأ رحلتك اليوم!"
      ],
      phoneText: "0552716666",
      formTitle: "إحجز مشوارك الآن",
      fields: {
        name: "الاسم الأول للعميل",
        phone: "رقم الجوال (يجب أن يبدأ بـ 05)",
        homeLocation: "مكان المنزل (الحي)",
        workLocation: "مكان الدوام (الحي)",
        deliveryCount: "عدد الأفراد",
        driverNationality: "جنسية السائق المطلوبة",
        notes: "أي ملاحظات أخرى",
      },
      submitButton: "إرسال الطلب",
      contactButton: "اضغط هنا للتواصل",
      qrText: "امسح الكود لبدء الدردشة عبر واتساب مع خدمة العملاء",
      tabs: {
        requestDetails: "تفاصيل الطلب",
        deliveryInfo: "معلومات التوصيل",
        driverInfo: "معلومات السائق",
      },
      footer: {
        followUs: "تابعونا على منصات التواصل الاجتماعي:",
        rights: "جميع الحقوق محفوظة لـ مشوارك نت",
      },
      languageButton: "إختر اللغة"
    },
    en: {
      header: "Book Your Ride Now",
      description: "Easy booking and comfort",
      descriptionSub: "with Mshwark Net",
      adText: [
        "Book Your Ride - Comfort at the Best Prices in Riyadh!",
        "With 'Book Your Ride', we offer fast and reliable transportation services within Riyadh. You can choose your driver manually, ensuring timely arrival at the best prices.",
        "Our services include transporting employees and students to their work and study locations. Choose from a variety of professional drivers and start your journey today!"
      ],
      phoneText: "0552716666",
      formTitle: "Book Your Ride Now",
      fields: {
        name: "Customer's First Name",
        phone: "Mobile Number (Must start with 05)",
        homeLocation: "Home Location (Neighborhood)",
        workLocation: "Work Location (Neighborhood)",
        deliveryCount: "Number of People",
        driverNationality: "Required Driver Nationality",
        notes: "Any Additional Notes",
      },
      submitButton: "Submit Request",
      contactButton: "Click Here to Contact",
      qrText: "Scan the code to start chatting with customer service on WhatsApp",
      tabs: {
        requestDetails: "Request Details",
        deliveryInfo: "Delivery Info",
        driverInfo: "Driver Info",
      },
      footer: {
        followUs: "Follow us on social media:",
        rights: "All rights reserved to Mshwark Net",
      },
      languageButton: "Choose Language"
    },
    hi: {
      header: "अपनी सवारी बुक करें",
      description: "आसान बुकिंग और आराम",
      descriptionSub: "Mshwark Net के साथ",
      adText: [
        "अपनी सवारी बुक करें - रियाद में सबसे अच्छी कीमतों पर आराम!",
        "'अपनी सवारी बुक करें' के साथ, हम रियाद में तेजी से और विश्वसनीय परिवहन सेवाएं प्रदान करते हैं। आप अपने ड्राइवर को मैन्युअली चुन सकते हैं, समय पर पहुंचने की गारंटी के साथ सबसे अच्छी कीमतों पर।",
        "हमारी सेवाओं में कर्मचारियों और छात्रों को उनके कार्य और अध्ययन स्थानों पर पहुँचाना शामिल है। पेशेवर ड्राइवरों की विविधता में से चुनें और आज ही अपनी यात्रा शुरू करें!"
      ],
      phoneText: "0552716666",
      formTitle: "अपनी सवारी बुक करें",
      fields: {
        name: "ग्राहक का पहला नाम",
        phone: "मोबाइल नंबर (05 से शुरू होना चाहिए)",
        homeLocation: "घर का स्थान (पड़ोस)",
        workLocation: "कार्य स्थान (पड़ोस)",
        deliveryCount: "लोगों की संख्या",
        driverNationality: "आवश्यक चालक राष्ट्रीयता",
        notes: "कोई अतिरिक्त नोट्स",
      },
      submitButton: "अनुरोध सबमिट करें",
      contactButton: "संपर्क के लिए यहां क्लिक करें",
      qrText: "व्हाट्सएप पर ग्राहक सेवा से चैट शुरू करने के लिए कोड स्कैन करें",
      tabs: {
        requestDetails: "अनुरोध विवरण",
        deliveryInfo: "डिलीवरी की जानकारी",
        driverInfo: "ड्राइवर की जानकारी",
      },
      footer: {
        followUs: "सोशल मीडिया पर हमें फॉलो करें:",
        rights: "सभी अधिकार Mshwark Net के लिए सुरक्षित हैं",
      },
      languageButton: "भाषा चुनें"
    },
    bn: {
      header: "আপনার রাইড বুক করুন",
      description: "সহজ বুকিং এবং স্বাচ্ছন্দ্য",
      descriptionSub: "'Mshwark Net' এর সাথে",
      adText: [
        "আপনার রাইড বুক করুন - রিয়াদে সেরা দামে স্বাচ্ছন্দ্য!",
        "'আপনার রাইড বুক করুন' এর মাধ্যমে, আমরা রিয়াদে দ্রুত এবং নির্ভরযোগ্য পরিবহন পরিষেবা প্রদান করি। আপনি আপনার ড্রাইভারটি নিজে বেছে নিতে পারবেন, সর্বোত্তম মূল্যে সময়মতো পৌঁছানোর নিশ্চয়তার সাথে।",
        "আমাদের পরিষেবাগুলির মধ্যে কর্মচারী এবং শিক্ষার্থীদের তাদের কাজ এবং অধ্যয়ন স্থানে পৌঁছে দেওয়া অন্তর্ভুক্ত। পেশাদার ড্রাইভারদের বিভিন্নতা থেকে চয়ন করুন এবং আজই আপনার যাত্রা শুরু করুন!"
      ],
      phoneText: "0552716666",
      formTitle: "আপনার রাইড বুক করুন",
      fields: {
        name: "গ্রাহকের প্রথম নাম",
        phone: "মোবাইল নম্বর (05 দিয়ে শুরু হওয়া উচিত)",
        homeLocation: "বাড়ির অবস্থান (পাড়ায়)",
        workLocation: "কাজের অবস্থান (পাড়ায়)",
        deliveryCount: "মানুষের সংখ্যা",
        driverNationality: "প্রয়োজনীয় চালকের জাতীয়তা",
        notes: "যেকোনো অতিরিক্ত নোট",
      },
      submitButton: "অনুরোধ জমা দিন",
      contactButton: "যোগাযোগ করতে এখানে ক্লিক করুন",
      qrText: "হোয়াট্সঅ্যাপে গ্রাহক পরিষেবার সাথে চ্যাট শুরু করতে কোড স্ক্যান করুন",
      tabs: {
        requestDetails: "অনুরোধের বিবরণ",
        deliveryInfo: "ডেলিভারি তথ্য",
        driverInfo: "ড্রাইভারের তথ্য",
      },
      footer: {
        followUs: "সোশ্যাল মিডিয়াতে আমাদের অনুসরণ করুন:",
        rights: "সমস্ত অধিকার Mshwark Net এর জন্য সংরক্ষিত",
      },
      languageButton: "ভাষা নির্বাচন করুন"
    },
  };

  const currentContent = content[language] || content.ar;

  return (
    <div className="min-h-screen flex flex-col items-center p-6 bg-gradient-to-r from-green-200 to-blue-200">
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="text-center mb-4 md:mb-0 md:w-1/2 flex flex-col items-center">
          <animated.img 
            src="/logo.png" 
            alt="مشوارك نت" 
            className="h-32 w-32 mx-auto"
            style={fade}
          />
          <animated.h1 
            className="text-5xl font-bold text-teal-900 mt-4 shadow-lg"
            style={fade}
          >
            {currentContent.header}
          </animated.h1>
          <p className="text-lg text-teal-900 mt-2">{currentContent.description}</p>
          <p className="text-lg text-teal-900 mt-1 mb-4">{currentContent.descriptionSub}</p>
          <p className="text-lg text-teal-900 mt-2 mb-4">{currentContent.phoneText}</p>
          <button onClick={openModal} className="contact-button bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all">
            {currentContent.contactButton}
          </button>
          <QRCodeCanvas id="qr_code" value="https://wa.me/966552716666" size={180} className="shadow-lg rounded-lg mt-4 mb-2" />
          <p className="text-lg text-gray-800 mt-2 mb-8">{currentContent.qrText}</p>
        </div>

        <div className="flex flex-col items-center bg-white bg-opacity-90 p-8 rounded-lg shadow-2xl max-w-4xl w-full md:w-1/2 mt-8">
          <h2 className="text-4xl font-bold text-teal-700 mb-6" style={{ padding: '10px' }}>{currentContent.description}</h2>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton className="text-center bg-teal-500 text-white py-2 rounded-lg mb-4 w-full">
                  إعرف المزيد
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  {currentContent.adText.map((text, index) => (
                    <p key={index} className="mb-2 text-lg text-gray-800">{text}</p>
                  ))}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <form onSubmit={sendRequest} className="w-full">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className="mb-8">
              <TabList className="flex justify-center space-x-4 mb-6">
                <Tab className={`py-2 px-6 rounded-lg cursor-pointer bg-gradient-to-r from-teal-600 to-teal-500 text-white ${tabIndex === 0 ? 'shadow-lg' : 'shadow-md'}`}>
                  {currentContent.tabs.requestDetails}
                </Tab>
                <Tab className={`py-2 px-6 rounded-lg cursor-pointer bg-gradient-to-r from-teal-600 to-teal-500 text-white ${tabIndex === 1 ? 'shadow-lg' : 'shadow-md'}`}>
                  {currentContent.tabs.deliveryInfo}
                </Tab>
                <Tab className={`py-2 px-6 rounded-lg cursor-pointer bg-gradient-to-r from-teal-600 to-teal-500 text-white ${tabIndex === 2 ? 'shadow-lg' : 'shadow-md'}`}>
                  {currentContent.tabs.driverInfo}
                </Tab>
              </TabList>

              <TabPanel>
                <div className="mb-6 flex items-center">
                  <FaUser className="text-teal-700 mr-3" />
                  <input 
                    type="text" 
                    name="customer_name" 
                    placeholder={currentContent.fields.name} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500" 
                    onChange={handleInputChange} 
                    value={formData.customer_name} 
                    required 
                  />
                </div>

                <div className="mb-6 flex items-center">
                  <FaPhone className="text-teal-700 mr-3" />
                  <input 
                    type="text" 
                    name="customer_phone" 
                    placeholder={currentContent.fields.phone} 
                    className={`w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500 ${phoneValid ? '' : 'border-red-500'}`} 
                    onChange={handleInputChange} 
                    value={formData.customer_phone} 
                    required 
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <div className="mb-6 flex items-center">
                  <FaHome className="text-teal-700 mr-3" />
                  <input 
                    type="text" 
                    name="home_location" 
                    placeholder={currentContent.fields.homeLocation} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500" 
                    onChange={handleInputChange} 
                    value={formData.home_location} 
                    required 
                  />
                </div>

                <div className="mb-6 flex items-center">
                  <FaMapMarkerAlt className="text-teal-700 mr-3" />
                  <input 
                    type="text" 
                    name="work_location" 
                    placeholder={currentContent.fields.workLocation} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500" 
                    onChange={handleInputChange} 
                    value={formData.work_location} 
                    required 
                  />
                </div>

                <div className="mb-6 flex items-center">
                  <FaMapMarkerAlt className="text-teal-700 mr-3" />
                  <input 
                    type="number" 
                    name="delivery_count" 
                    placeholder={currentContent.fields.deliveryCount} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500" 
                    onChange={handleInputChange} 
                    value={formData.delivery_count} 
                    required 
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <div className="mb-6 flex items-center">
                  <FaCarSide className="text-teal-700 mr-3" />
                  <input 
                    type="text" 
                    name="driver_nationality" 
                    placeholder={currentContent.fields.driverNationality} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500" 
                    onChange={handleInputChange} 
                    value={formData.driver_nationality} 
                    required 
                  />
                </div>

                <div className="mb-6 flex items-center">
                  <FaMapMarkerAlt className="text-teal-700 mr-3" />
                  <textarea 
                    name="additional_notes" 
                    placeholder={currentContent.fields.notes} 
                    className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                    onChange={handleInputChange}
                    value={formData.additional_notes}
                  ></textarea>
                </div>
              </TabPanel>
            </Tabs>

            <button type="submit" className={`w-full ${formFilled ? 'bg-teal-500' : 'bg-gray-400'} text-white py-3 rounded-lg shadow-lg transition-all`} disabled={!formFilled}>
              {currentContent.submitButton}
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Contact Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <animated.img 
          src="/logo.png" 
          alt="مشوارك نت" 
          className="h-16 w-16 mx-auto"
          style={fade}
        />
        <h2 className="text-2xl font-bold mb-4 text-center">اتصال أو واتساب</h2>
        <QRCodeCanvas id="qr_code_modal" value="https://wa.me/966552716666" size={120} className="shadow-lg rounded-lg mt-4 mb-2" />
        <div className="h-1 bg-teal-500 w-full mb-4"></div>
        <div className="flex justify-around w-full">
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => window.open('https://wa.me/966552716666', '_blank')}
            className="bg-green-500 text-white py-2 px-4 rounded-full shadow-md">
            <FaWhatsapp size={24} />
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => (window.innerWidth <= 768 ? window.open('tel:0552716666') : copyPhoneNumber())}
            className="bg-teal-500 text-white py-2 px-4 rounded-full shadow-md">
            <FaPhoneAlt size={24} />
          </motion.button>
        </div>
        <button 
          onClick={closeModal} 
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إغلاق
        </button>
      </Modal>

      <Modal
        isOpen={isLanguageModalOpen}
        onRequestClose={closeModal}
        contentLabel="Language Selection Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <animated.img 
          src="/logo.png" 
          alt="مشوارك نت" 
          className="h-16 w-16 mx-auto"
          style={fade}
        />
        <h2 className="text-2xl font-bold mb-4 text-center">اختر اللغة</h2>
        <div className="h-1 bg-teal-500 w-full mb-4"></div>
        <div className="flex justify-around w-full">
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => switchLanguage('ar')}
            className="bg-gray-200 text-black py-2 px-4 rounded-full shadow-md">
            العربية
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => switchLanguage('en')}
            className="bg-gray-200 text-black py-2 px-4 rounded-full shadow-md">
            English
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => switchLanguage('hi')}
            className="bg-gray-200 text-black py-2 px-4 rounded-full shadow-md">
            हिंदी
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
            onClick={() => switchLanguage('bn')}
            className="bg-gray-200 text-black py-2 px-4 rounded-full shadow-md">
            বাংলা
          </motion.button>
        </div>
        <button 
          onClick={closeModal} 
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إغلاق
        </button>
      </Modal>

      <button 
        onClick={openLanguageModal} 
        className="fixed bottom-4 left-4 bg-teal-500 text-white py-2 px-4 rounded-full shadow-md z-50 hover:bg-teal-700 transition-all"
      >
        {currentContent.languageButton}
      </button>

      <footer className="w-full text-center mt-12 text-teal-900">
        <p className="mb-4">{currentContent.footer.followUs}</p>
        <div className="flex justify-center space-x-6">
          <a href="https://x.com/Mshwarknet" className="text-2xl"><FaTwitter /></a>
          <a href="https://wa.me/966552716666" className="text-2xl"><FaWhatsapp /></a>
          <a href="mailto:mshwarknet@gmail.com" className="text-2xl"><FaEnvelope /></a>
        </div>
        <p className="mt-6">  {currentContent.footer.rights} &copy; 2024</p>
      </footer>
    </div>
  );
}

export default HomePage;
