import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaWhatsapp, FaTrashAlt, FaShareAlt, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [newDriver, setNewDriver] = useState({ name: '', phone: '' });
  const [filter, setFilter] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [driverNote, setDriverNote] = useState(''); // ملاحظات السائق
  const [sharePhone, setSharePhone] = useState(''); // الهاتف الذي سيتم المشاركة معه
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalOrders, setTotalOrders] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://mshwark.net/get_orders.php');
        const data = await response.json();
        if (Array.isArray(data)) {
          const ordersWithNotesArray = data.map(order => {
            if (!Array.isArray(order.driver_notes)) {
              order.driver_notes = order.driver_notes ? order.driver_notes.split('\n') : [];
            }
            return order;
          });
          setOrders(ordersWithNotesArray);
          setFilteredOrders(ordersWithNotesArray);
          setTotalOrders(ordersWithNotesArray.length);
        } else {
          toast.error("حدث خطأ أثناء جلب الطلبات من قاعدة البيانات.");
        }
      } catch (error) {
        toast.error("حدث خطأ أثناء الاتصال بقاعدة البيانات.");
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await fetch('https://mshwark.net/get_drivers.php');
        const data = await response.json();
        setDrivers(data);
      } catch (error) {
        toast.error("حدث خطأ أثناء جلب قائمة السائقين.");
      }
    };

    fetchDrivers();
  }, []);

  useEffect(() => {
    let filtered = orders.filter(order =>
      Object.values(order).some(val =>
        String(val).toLowerCase().includes(filter.toLowerCase())
      )
    );

    if (startDate && endDate) {
      filtered = filtered.filter(order => {
        const orderDate = new Date(order.created_at);
        return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
      });
    }

    if (sortOrder === 'asc') {
      filtered.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    } else {
      filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    setFilteredOrders(filtered);
  }, [filter, orders, startDate, endDate, sortOrder]);

  const openModal = (order) => {
    setSelectedOrder(order);
    setDriverNote(''); // Reset driver note when opening the modal
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const openNoteModal = (order) => {
    setSelectedOrder(order);
    setDriverNote(''); // Reset driver note when opening the modal
    setIsNoteModalOpen(true);
  };

  const closeNoteModal = () => {
    setIsNoteModalOpen(false);
    setSelectedOrder(null);
  };

  const openDriverModal = () => {
    setIsDriverModalOpen(true);
  };

  const closeDriverModal = () => {
    setIsDriverModalOpen(false);
    setNewDriver({ name: '', phone: '' });
  };

  const openShareModal = (order) => {
    setSelectedOrder(order);
    setSharePhone('');
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setSelectedOrder(null);
  };

  const handleDriverSave = async () => {
    try {
      const response = await fetch('https://mshwark.net/add_driver.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          driver_name: newDriver.name,
          driver_phone: newDriver.phone,
        }),
      });

      const responseText = await response.text();

      if (response.ok) {
        setDrivers([...drivers, newDriver]);
        toast.success('تم إضافة السائق بنجاح!');
        closeDriverModal();
      } else {
        toast.error('حدث خطأ أثناء إضافة السائق: ' + responseText);
      }
    } catch (error) {
      toast.error('حدث خطأ أثناء إضافة السائق.');
    }
  };

  const handleStatusChange = async (order) => {
    try {
      const response = await fetch('https://mshwark.net/update_order_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          order_id: order.order_id,
          status: order.status ? 0 : 1
        })
      });

      if (response.ok) {
        toast.success("تم تحديث حالة الطلب بنجاح!");
        setOrders(orders.map(o => o.order_id === order.order_id ? { ...o, status: order.status ? 0 : 1 } : o));
      } else {
        toast.error("حدث خطأ أثناء تحديث حالة الطلب.");
      }
    } catch (error) {
      toast.error("حدث خطأ أثناء تحديث حالة الطلب.");
    }
  };

  const handleSave = async (order) => {
    if (!selectedOrder) {
      toast.error('لم يتم اختيار الطلب لحفظ الملاحظات.');
      return;
    }

    const updatedNotes = [...(order.driver_notes || []), driverNote];

    try {
      const response = await fetch('https://mshwark.net/update_order_note.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          order_id: selectedOrder.order_id,
          driver_note: updatedNotes.join('\n'), // Save the driver note state as a string
        }),
      });

      if (response.ok) {
        toast.success('تم حفظ الملاحظات بنجاح!');
        setOrders((orders) =>
          orders.map((o) =>
            o.order_id === selectedOrder.order_id
              ? { ...o, driver_notes: updatedNotes }
              : o
          )
        );
        closeNoteModal();
      } else {
        toast.error('حدث خطأ أثناء حفظ الملاحظات.');
      }
    } catch (error) {
      toast.error('حدث خطأ أثناء حفظ الملاحظات.');
    }
  };

  const handleDriverInfoSave = async (order, driverName, driverPhone) => {
    try {
      const response = await fetch('https://mshwark.net/update_driver_info.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          order_id: order.order_id,
          driver_name: driverName,
          driver_phone: driverPhone
        })
      });

      if (response.ok) {
        toast.success("تم تحديث معلومات السائق بنجاح!");
        setOrders(orders.map(o => o.order_id === order.order_id ? { ...o, driver_name: driverName, driver_phone: driverPhone } : o));
      } else {
        toast.error("حدث خطأ أثناء تحديث معلومات السائق.");
      }
    } catch (error) {
      toast.error("حدث خطأ أثناء تحديث معلومات السائق.");
    }
  };

  const handleDelete = async (orderId) => {
    if (window.confirm("هل أنت متأكد من حذف الطلب؟")) {
      try {
        const response = await fetch('https://mshwark.net/delete_order.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({ order_id: orderId })
        });

        if (response.ok) {
          setOrders(orders.filter(order => order.order_id !== orderId));
          setFilteredOrders(filteredOrders.filter(order => order.order_id !== orderId));
          toast.success("تم حذف الطلب بنجاح!");
        } else {
          toast.error("حدث خطأ أثناء حذف الطلب.");
        }
      } catch (error) {
        toast.error("حدث خطأ أثناء حذف الطلب.");
      }
    }
  };

  const handleShare = (order) => {
    if (!order) {
      toast.error('لم يتم اختيار الطلب بشكل صحيح.');
      return;
    }

    const message = `
      طلب حجز جديد باسم: ${order.customer_name}
      رقم الطلب: ${order.order_id}
      رقم الهاتف: ${order.customer_phone}
      مكان المنزل: ${order.home_location}
      مكان الدوام: ${order.work_location}
      عدد الأفراد: ${order.delivery_count}
      جنسية السائق المطلوبة: ${order.driver_nationality}
      اسم السائق: ${order.driver_name}
      رقم السائق: ${order.driver_phone}
      رابط محادثة واتساب مع العميل: https://wa.me/966${order.customer_phone}
      ملاحظات مقدم الطلب: ${order.additional_notes}
      ملاحظات للسائق: ${order.driver_notes.join('\n')}
    `;
    const whatsappUrl = `https://wa.me/966${sharePhone}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    closeShareModal();
  };

  const handleLogin = () => {
    if (password === '123456') {
      setAuthenticated(true);
    } else {
      toast.error("كلمة المرور غير صحيحة");
    }
  };

  const indexOfLastOrder = currentPage * rowsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - rowsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredOrders.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (!authenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-200">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold mb-4 text-center">تسجيل الدخول</h1>
          <input
            type="password"
            placeholder="أدخل كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded-lg mb-4"
          />
          <button
            onClick={handleLogin}
            className="bg-teal-500 text-white w-full py-2 rounded-lg"
          >
            تسجيل الدخول
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gradient-to-r from-green-200 to-blue-200 min-h-screen">
      <ToastContainer />
      <div className="text-center mb-4">
        <img src="/logo.png" alt="Logo" className="mx-auto w-16 h-16" />
        <h1 className="text-4xl text-teal-900 mb-8">طلبات الحجز</h1>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="بحث..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="w-full md:w-1/3 p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
        />
        <div className="flex space-x-4">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <button onClick={openDriverModal} className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all">
          <FaPlus className="inline mr-2" /> إضافة سائق جديد
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <div>
          عرض {currentOrders.length} من {totalOrders} طلبات
        </div>
        <div>
          <label>عدد الطلبات في الصفحة:</label>
          <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
            className="p-2 ml-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div>
          <button
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all"
          >
            {sortOrder === 'asc' ? 'فرز تنازلي' : 'فرز تصاعدي'}
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white rounded-lg shadow-md table-auto">
        <thead>
          <tr className="bg-teal-500 text-white">
            <th className="py-2 px-4">مسلسل</th>
            <th className="py-2 px-4">رقم الطلب</th>
            <th className="py-2 px-4">الاسم</th>
            <th className="py-2 px-4">رقم الجوال</th>
            <th className="py-2 px-4">مكان المنزل</th>
            <th className="py-2 px-4">مكان الدوام</th>
            <th className="py-2 px-4">عدد الأفراد</th>
            <th className="py-2 px-4">جنسية السائق</th>
            <th className="py-2 px-4">اسم السائق</th>
            <th className="py-2 px-4">رقم السائق</th>
            <th className="py-2 px-4">تاريخ ووقت الطلب</th>
            <th className="py-2 px-4">ملاحظات مقدم الطلب</th>
            <th className="py-2 px-4">ملاحظات للسائق</th>
            <th className="py-2 px-4">الحالة</th>
            <th className="py-2 px-4">خيارات</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.length > 0 ? (
            currentOrders.map((order, index) => (
              <tr key={order.order_id} className={`border-b ${order.status ? 'bg-gray-200 line-through' : ''}`}>
                <td className="py-2 px-4">{indexOfFirstOrder + index + 1}</td>
                <td className="py-2 px-4">{order.order_id}</td>
                <td className="py-2 px-4">{order.customer_name}</td>
                <td className="py-2 px-4">
                  <button onClick={() => openModal(order)}>
                    {order.customer_phone}
                  </button>
                </td>
                <td className="py-2 px-4">{order.home_location}</td>
                <td className="py-2 px-4">{order.work_location}</td>
                <td className="py-2 px-4">{order.delivery_count}</td>
                <td className="py-2 px-4">{order.driver_nationality}</td>
                <td className="py-2 px-4">
                  <select
                    value={order.driver_name || ""}
                    onChange={(e) => handleDriverInfoSave(order, e.target.value, drivers.find(d => d.driver_name === e.target.value)?.driver_phone)}
                    className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="">اختر السائق</option>
                    {drivers.map((driver, idx) => (
                      <option key={idx} value={driver.driver_name}>{driver.driver_name}</option>
                    ))}
                  </select>
                </td>
                <td className="py-2 px-4">
                  {order.driver_phone ? (
                    <button onClick={() => handleShare(order)} className="text-teal-500 underline">
                      {order.driver_phone}
                    </button>
                  ) : (
                    'لا يوجد رقم'
                  )}
                </td>
                <td className="py-2 px-4">{new Date(order.created_at).toLocaleString()}</td>
                <td className="py-2 px-4">
                  <ul>
                    {order.driver_notes.map((note, idx) => (
                      <li key={idx}>{note}</li>
                    ))}
                  </ul>
                </td>
                <td className="py-2 px-4">
                  <button onClick={() => openNoteModal(order)} className="bg-teal-500 text-white py-1 px-3 rounded-lg shadow-md hover:bg-teal-700 transition-all">
                    إدخال ملاحظة
                  </button>
                </td>
                <td className="py-2 px-4">
                  <input
                    type="checkbox"
                    checked={order.status}
                    onChange={() => handleStatusChange(order)}
                    className="transform scale-150"
                  />
                </td>
                <td className="py-2 px-4 flex justify-center items-center space-x-2">
                  <button onClick={() => handleDelete(order.order_id)} className="bg-red-500 text-white py-1 px-3 rounded-lg shadow-md hover:bg-red-700 transition-all">
                    <FaTrashAlt />
                  </button>
                  <button onClick={() => openShareModal(order)} className="bg-teal-500 text-white py-1 px-3 rounded-lg shadow-md hover:bg-teal-700 transition-all">
                    <FaShareAlt />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="15" className="text-center py-4">لا توجد طلبات متاحة</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="mt-4 flex justify-center">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`px-3 py-2 mx-1 rounded ${number === currentPage ? 'bg-teal-500 text-white' : 'bg-white text-teal-500'}`}
          >
            {number}
          </button>
        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Contact Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">اتصال أو واتساب</h2>
        <div className="h-1 bg-teal-500 w-full mb-4"></div>
        <div className="flex justify-around w-full">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => window.open(`tel:${selectedOrder.customer_phone}`, '_blank')}
            className="bg-teal-500 text-white py-2 px-4 rounded-full shadow-md">
            <FaPhoneAlt size={24} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => window.open(`https://wa.me/966${selectedOrder.customer_phone}`, '_blank')}
            className="bg-green-500 text-white py-2 px-4 rounded-full shadow-md">
            <FaWhatsapp size={24} />
          </motion.button>
        </div>
        <button
          onClick={closeModal}
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إغلاق
        </button>
      </Modal>

      <Modal
        isOpen={isDriverModalOpen}
        onRequestClose={closeDriverModal}
        contentLabel="Add Driver Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">إضافة سائق جديد</h2>
        <div className="h-1 bg-teal-500 w-full mb-4"></div>
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="اسم السائق"
            value={newDriver.name}
            onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })}
            className="mb-4 p-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
          <input
            type="text"
            placeholder="رقم الهاتف"
            value={newDriver.phone}
            onChange={(e) => setNewDriver({ ...newDriver, phone: e.target.value })}
            className="mb-4 p-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <button
          onClick={handleDriverSave}
          className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all"
        >
          <FaPlus className="inline mr-2" /> حفظ السائق
        </button>
        <button
          onClick={closeDriverModal}
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إلغاء
        </button>
      </Modal>

      <Modal
        isOpen={isNoteModalOpen}
        onRequestClose={closeNoteModal}
        contentLabel="Driver Note Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">إدخال ملاحظات للسائق</h2>
        <textarea
          value={driverNote}
          onChange={(e) => setDriverNote(e.target.value)}
          className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
          rows="3"
        />
        <button
          onClick={() => handleSave(selectedOrder)}
          className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all mt-4"
        >
          حفظ الملاحظات
        </button>
        <button
          onClick={closeNoteModal}
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إغلاق
        </button>
      </Modal>

      <Modal
        isOpen={isShareModalOpen}
        onRequestClose={closeShareModal}
        contentLabel="Share Order Modal"
        className="modal-content bg-white p-6 rounded-lg shadow-lg flex flex-col items-center fixed inset-0 z-50 w-80 h-auto"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">مشاركة معلومات الطلب</h2>
        <input
          type="text"
          placeholder="رقم الهاتف (05xxxxxxxx)"
          value={sharePhone}
          onChange={(e) => setSharePhone(e.target.value)}
          className="w-full p-3 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-teal-500 mb-4"
        />
        <button
          onClick={() => handleShare(selectedOrder)}
          className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-teal-700 transition-all"
        >
          إرسال
        </button>
        <button
          onClick={closeShareModal}
          className="mt-4 text-teal-700 hover:text-teal-900 transition-all"
        >
          إلغاء
        </button>
      </Modal>
    </div>
  );
}

export default OrdersPage;
